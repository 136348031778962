
<template>
  <section id="HomeVideo">
    <div class="container">
      <div class="content_box">
        <header class="header">
          <p class="sub_title"><b>VIDEO GALLERY</b></p>
          <h3 class="title title_mark"><b>影音專區</b></h3>
        </header>

        <div class="content">
          <router-link
            @click.native="GA_ClickHomeLink('影音專區')"
            to="/video/list/1"
            class="more_btn link_mark"
          >
            <div><b>LEARN MORE</b></div>
          </router-link>
        </div>
      </div>
      <div class="red_cover"></div>
      <img src="/img/bgworks.jpg" />
    </div>
  </section>
</template>

<script>
import HomeVideoGSAP from "@/common/GSAP/Home/home_video.js"
export default {
  name: "HomeVideo",
  mixins: [HomeVideoGSAP],
  props: {
    image_load_status: {
      require: true
    }
  },
  mounted() {
    this.InitGSAP()
  },
  watch: {
    image_load_status: {
      handler() {
        let loaded = true
        this.image_load_status.forEach(item => {
          if (item != true) {
            loaded = false
          }
        });
        loaded ? this.Refresh() : ""
      },
      deep: true,
      immediate: true
    },
  }
}
</script>