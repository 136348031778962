<template>
  <section id="ProductFeature">
    <div class="row" v-if="product_feature != null">
      <picture class="img_box">
        <img @load="onImgLoad(1)" :src="product_feature.image" />
      </picture>
      <div class="content_box">
        <header>
          <p class="sub_title">
            <b>{{ product_feature.sub_title }}</b>
          </p>
          <h3 class="title title_mark">
            <b>{{ product_feature.title }}</b>
          </h3>
        </header>
        <div class="content" v-html="product_feature.content"></div>
      </div>

      <div class="bg_text_box">
        <span>PRODUCT</span>
        <span>FEATURES</span>
      </div>
    </div>
  </section>
</template>

<script>
import home_about_gsap from "@/common/GSAP/Home/home_product_feature.js"
export default {
  name: "ProductFeature",
  mixins: [home_about_gsap],
  props: {
    page_data: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      image_loaded: 0
    }
  },
  computed: {
    product_feature() {
      return this.page_data[0]
    }
  },
  methods: {
    onImgLoad() {
      // console.log(`feature ${index} is loaded`)
      this.image_loaded += 1
      if (this.image_loaded == 1) {
        this.$emit("image-loaded")
      }
    }
  },
  mounted() {
    this.InitGSAP()
  },
}
</script>