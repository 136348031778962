import {
    get,
    post
} from '@/common/request'

export function getPage() {
    //輪播
    var carousel_data = get('web_edit/home_carousel/get_home_carousel.php');
    //關於
    var about = post('web_edit/get_common_column.php', {
        column_array: ["about"]
    })
    //特色介紹
    var product_feature = post('web_edit/get_common_column.php', {
        column_array: ["home_product_1"]
    })
    //工廠認證介紹
    var factory = post('web_edit/get_common_column.php', {
        column_array: ["home_factory"]
    })
    //熱門商品
    var hot_products = get('web_edit/home_hot_product/get_home_hot_product.php')
    //客製化介紹
    var customize = post('web_edit/get_common_column.php', {
        column_array: ["home_customize"]
    })
    //底部連結
    var bottom_links = post('web_edit/get_common_column.php', {
        column_array: ["home_bottom_link_1", "home_bottom_link_2"]
    })
    //最新消息
    var news = get('news/get_news_list.php')

    let promise_list = [carousel_data, about, product_feature, hot_products, customize, bottom_links, news, factory]

    return Promise.all(GetPromise(promise_list))
        .then(
            res => {
                if (promise_list.length == res.length) {
                    return (res)
                }
            }, err => console.log(err)
        )
}

function GetPromise(promiseList) {
    return promiseList.map(promise =>
        promise.then((res) => res)
    )
}

export function getCarousel() {
    return get('web_edit/home_carousel/get_home_carousel.php')
}

export function getAbout() {
    return post('web_edit/get_common_column.php', {
        column_array: ["about"]
    })
}

export function getProductFeature() {
    return post('web_edit/get_common_column.php', {
        column_array: ["home_product_1"]
    })
}

export function getHotProducts() {
    return get('web_edit/home_hot_product/get_home_hot_product.php')
}

export function getCustomize() {
    return post('web_edit/get_common_column.php', {
        column_array: ["home_customize"]
    })
}

export function getBottomLink() {
    return post('web_edit/get_common_column.php', {
        column_array: ["home_bottom_link_1", "home_bottom_link_2"]
    })
}

export function getNewsList() {
    return get('news/get_news_list.php')
}