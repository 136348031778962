<template>
  <section id="HomeCustomize">
    <div class="row">
      <picture class="img_box">
        <img
          @load="onImgLoad(1)"
          style="z-index: 4"
          src="/img/color_choose/3.webp"
        />
        <img
          @load="onImgLoad(2)"
          style="z-index: 3"
          src="/img/color_choose/2.webp"
        />
        <img
          @load="onImgLoad(3)"
          style="z-index: 2"
          src="/img/color_choose/1.webp"
        />
        <img @load="onImgLoad(4)" src="/img/color_choose/base.webp" />
      </picture>
      <div class="content_box" v-if="content_data != null">
        <header>
          <hgroup>
            <h3 class="title title_mark">
              <b>{{ content_data.title }}</b>
            </h3>
            <h4 class="sub_title">
              <b>{{ content_data.sub_title }}</b>
            </h4>
          </hgroup>
        </header>

        <div class="content" v-html="content_data.content"></div>
        <router-link
          @click.native="GA_ClickHomeLink('客製化專區')"
          to="/customize_list"
          class="more_btn link_mark"
        >
          <div><b>LEARN MORE</b></div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import HomeCustomizeGSAP from '@/common/GSAP/Home/home_customize.js';
export default {
  name: 'Customize',
  mixins: [HomeCustomizeGSAP],
  props: {
    page_data: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      image_loaded: 0,
    };
  },
  computed: {
    content_data() {
      return this.page_data[0];
    },
  },
  methods: {
    onImgLoad() {
      // console.log(`customize ${index} is loaded`)
      this.image_loaded += 1;
      if (this.image_loaded == 4) {
        this.$emit('image-loaded');
      }
    },
  },
  mounted() {
    this.InitGSAP();
  },
};
</script>
