<template>
  <div ref="app" id="app">
    <MainHeader ref="MainHeader" />
    <MainAside v-on:toggle-menu="ToggleMenu" />
    <MainImageModal />
    <MainLoading />
    <MainDialog />
    <div id="MenuCover"></div>

    <button
      @click="ScrollToTop"
      class="go_top_btn top_mark"
      :class="{ active: go_top_btn }"
    >
      <span class="material-icons-outlined"> keyboard_arrow_up </span>
    </button>
    <router-view
      ref="RouterView"
      v-on:toggle-go-top="ToggleGoTopBtn"
      v-on:init-cursor="InitCursor"
    />
  </div>
</template>

<style lang="scss" src="./assets/style.scss">
</style>


<script>
import MainHeader from "@/components/MainHeader"
import MainAside from "@/components/MainAside"
import MainImageModal from "@/components/MainImageModal"
import MainLoading from "@/components/MainLoading"
import MainDialog from "@/components/MainDialog"
import SmoothScrollResister from "@/common/SmoothScrollResister"
export default {
  name: 'App',
  components: {
    MainHeader,
    MainAside,
    MainImageModal,
    MainLoading,
    MainDialog
  },
  mixins: [SmoothScrollResister],
  data() {
    return {
      go_top_btn: false
    }
  },
  watch: {
    "$route"() {
      this.go_top_btn = false
      document.getElementById("mouse_arrow").className = "mouse_arrow";
    },
    "$store.state.loading"() {
      if (this.$store.state.loading != 0) {
        this.ModalToggle(true)
      } else {
        this.ModalToggle(false)
      }
    }
  },
  methods: {
    MouseMove(e) {
      let cursor = document.getElementById("mouse_arrow")
      let w = cursor.offsetWidth
      let h = cursor.offsetHeight
      cursor.style.transform = "translate3D(" + (e.clientX - w / 2) + "px," + (e.clientY - h / 2) + "px,0px)";
    },
    ToggleMenu() {
      this.$refs.MainHeader.ToggleMenu()
    },
    InitCursor() {
      if (!("ontouchstart" in document.documentElement)) {
        window.addEventListener('mousemove', ($event) => {
          this.MouseMove($event)
        })

        document.querySelectorAll(".title_mark").forEach(item => {
          item.addEventListener("mouseover", () => {
            document.getElementById("mouse_arrow").classList.add("title_hover");
          })
          item.addEventListener("mouseout", () => {
            document.getElementById("mouse_arrow").classList.remove("title_hover");
          })
        })

        document.querySelectorAll(".link_mark").forEach(item => {
          item.addEventListener("mouseover", () => {
            document.getElementById("mouse_arrow").classList.add("link_hover");
          })
          item.addEventListener("mouseout", () => {
            document.getElementById("mouse_arrow").classList.remove("link_hover");
          })
        })
        document.querySelectorAll(".more_mark").forEach(item => {
          item.addEventListener("mouseover", () => {
            document.getElementById("mouse_arrow").classList.add("more_hover");
          })
          item.addEventListener("mouseout", () => {
            document.getElementById("mouse_arrow").classList.remove("more_hover");
          })
        })
        document.querySelectorAll(".zoom_mark").forEach(item => {
          item.addEventListener("mouseover", () => {
            document.getElementById("mouse_arrow").classList.add("zoom_hover");
          })
          item.addEventListener("mouseout", () => {
            document.getElementById("mouse_arrow").classList.remove("zoom_hover");
          })
        })

        document.querySelectorAll(".top_mark").forEach(item => {
          item.addEventListener("mouseover", () => {
            document.getElementById("mouse_arrow").classList.add("top_hover");
          })
          item.addEventListener("mouseout", () => {
            document.getElementById("mouse_arrow").classList.remove("top_hover");
          })
        })


      }
    },
    ScrollToTop() {
      this.$refs.RouterView.ScrollToEl(0)
    },
    ToggleGoTopBtn(action) {
      this.go_top_btn = action
    }
  },
  mounted() {
  }
}
</script>
