<template>
  <section id="HomeDealerSupport">
    <div class="row">
      <router-link
        :to="item.link"
        class="link_mark"
        v-for="item in page_data"
        :key="`dealer_${item.column_id}`"
        @click.native="GA_ClickHomeLink(item.title)"
      >
        <div class="content_box">
          <div class="content">
            <h4>{{ item.title }}</h4>
            <p>{{ item.sub_title }}</p>
          </div>
          <picture>
            <img :src="item.image" />
          </picture>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeDealerSupport",
  props: {
    page_data: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
    }
  },
}
</script>