import gsap from "gsap";
gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});
export default {
    data() {
        return {
            loop_state: null,
            active_carousel: 0,
            total_section: 0,
            carousel_timeline: null
        }
    },
    methods: {
        NextCarousel(val) {
            let vm = this
            let prev_carousel = this.active_carousel
            this.active_carousel = val
            // console.log(this.active_carousel)
            let sections = document.querySelectorAll(".section")
            //active one out
            //text > left = right
            let active_section = sections[prev_carousel]
            let text = active_section.querySelectorAll(".content_box")[0]
            let left_img = active_section.querySelectorAll(".left_img")[0]
            let right_img = active_section.querySelectorAll(".right_img")[0]
            let top_img = active_section.querySelectorAll(".top_img")[0]
            vm.carousel_timeline = gsap.timeline({
                onComplete: () => {
                    vm.loop_state = setTimeout(() => {
                        vm.NextCarousel(vm.GetNextCarousel(val));
                    }, 3000)
                }
            })
            vm.carousel_timeline.fromTo(text, {
                opacity: 1,
                webkitFilter: "blur(0px)"
            }, {
                opacity: 0,
                webkitFilter: "blur(10px)",
                duration: .8
            }, 1)
            vm.carousel_timeline.fromTo(left_img, {
                x: "0",
                z: "1px",
                opacity: 1
            }, {
                x: "-100px",
                z: "1px",
                opacity: 0,
                duration: .5
            }, 1)
            vm.carousel_timeline.fromTo(right_img, {
                x: "0",
                z: "1px",
                opacity: 1
            }, {
                x: "100px",
                z: "1px",
                opacity: 0,
                duration: .5
            }, 1)
            vm.carousel_timeline.fromTo(top_img, {
                y: "0",
                z: "1px",
                opacity: 1
            }, {
                y: "100px",
                z: "1px",
                opacity: 0,
                duration: .5
            }, 1)



            //new one active
            let next_section = sections[this.active_carousel]
            let next_text = next_section.querySelectorAll(".content_box")[0]
            let next_left_img = next_section.querySelectorAll(".left_img")[0]
            let next_right_img = next_section.querySelectorAll(".right_img")[0]
            let next_top_img = next_section.querySelectorAll(".top_img")[0]
            vm.carousel_timeline.set(next_section, {
                position: "relative",
                opacity: 1
            }, 2)
            vm.carousel_timeline.set(active_section, {
                position: "absolute",
                opacity: 0
            }, 2)
            vm.carousel_timeline.fromTo(next_left_img, {
                x: "-100px",
                opacity: 0,
                delay: .2
            }, {
                x: "0",
                opacity: 1,
                duration: .5
            }, 2)
            vm.carousel_timeline.fromTo(next_right_img, {
                x: "100px",
                opacity: 0
            }, {
                x: "0",
                opacity: 1,
                duration: .5
            }, 2)
            vm.carousel_timeline.fromTo(next_top_img, {
                y: "100px",
                opacity: 0
            }, {
                y: "0",
                opacity: 1,
                duration: .5
            }, 2)

            vm.carousel_timeline.fromTo(next_text, {
                opacity: 0,
                webkitFilter: "blur(10px)"
            }, {
                opacity: 1,
                webkitFilter: "blur(0px)",
                duration: .8
            }, 2)

        },
        CarouselLoop() {
            this.loop_state = gsap.delayedCall(3, () => {
                this.active_carousel = this.active_carousel >= this.total_section - 1 ? 0 : this.active_carousel + 1
                this.NextCarousel(this.active_carousel);
                this.CarouselLoop();
            });
        },
        ToggleCarousel(val) {
            this.carousel_timeline.kill()
            clearTimeout(this.loop_state)
            this.NextCarousel(val)
        },
        InitGSAP(val) {
            this.total_section = val
            let vm = this
            let sections = document.querySelectorAll(".section")
            vm.carousel_timeline = gsap.timeline({
                onComplete: () => {
                    vm.loop_state = setTimeout(() => {
                        vm.NextCarousel(vm.GetNextCarousel(0));
                    }, 3000)
                }
            })
            sections.forEach((section, section_index) => {
                if (section_index != 0) {
                    vm.carousel_timeline.set(section, {
                        position: "absolute",
                        opacity: 0,
                        duration: 0
                    })
                }
            })
            let next_section = sections[0]
            let next_text = next_section.querySelectorAll(".content_box")[0]
            let next_left_img = next_section.querySelectorAll(".left_img")[0]
            let next_right_img = next_section.querySelectorAll(".right_img")[0]
            vm.carousel_timeline.set(next_section, {
                position: "relative",
                opacity: 1,
                duration: 0
            })
            vm.carousel_timeline.fromTo(next_left_img, {
                x: "-100px",
                opacity: 0,
                z: 0
            }, {
                x: "0",
                z: 0,
                opacity: 1,
                duration: .5,
                ease: "none"
            }, 1)
            vm.carousel_timeline.fromTo(next_right_img, {
                x: "100px",
                opacity: 0,
                z: "1px",
            }, {
                x: "0",
                z: "0",
                opacity: 1,
                duration: .5,
                ease: "none"
            }, 1)
            vm.carousel_timeline.fromTo(next_text, {
                opacity: 0,
                webkitFilter: "blur(10px)"
            }, {
                opacity: 1,
                webkitFilter: "blur(0px)",
                duration: .8
            }, 1)
        },
        GetNextCarousel(val) {
            if (val >= this.total_section - 1) {
                return 0
            } else {
                return val + 1
            }
        }
    },
    beforeDestroy() {
        this.carousel_timeline == null ? "" : this.carousel_timeline.kill()
        clearTimeout(this.loop_state)
    }
}