var default_meta = {
  title: '首頁',
  content:
    '茂建科技有限公司成立於 2015 年，以研究發展機車改裝避震器為主力，目前經營導向為代工（OEM、ODM）及自創品牌MJP，皆有不錯迴響。本著把最好的產品品質交到客人手上為使命，研發製造避震器，把專業及為客人騎乘舒適為著想的態度注入每支避震器中，是本公司對每一位客人的重視。',
  image: 'https://www.mjp.com.tw/img/share.jpg',
};

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title;
  return {
    title: 'MJP 茂建科技 - ' + title + ' | 客製化懸吊 | 避震器',
    meta: [
      {
        property: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        content: 'MJP 茂建科技 - ' + title + ' | 客製化懸吊 | 避震器',
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        content: 'MJP 茂建科技 - ' + title + ' | 客製化懸吊 | 避震器',
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        content: 'MJP 茂建科技 - ' + title + ' | 客製化懸吊 | 避震器',
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}
