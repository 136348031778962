<template>
  <section id="HomeNews">
    <div class="row">
      <div class="header">
        <header class="title_box">
          <h3 class="title title_mark"><b>最新消息</b></h3>
        </header>

        <router-link to="/news/list/all/" class="more_btn link_mark"
          >LEARN MORE<span></span
        ></router-link>
      </div>

      <div class="list">
        <template v-if="page_data != null && page_data.length > 0">
          <router-link
            class="card"
            :to="`/news/page/${item.news_id}`"
            v-for="(item, item_index) in page_data"
            :key="item.id"
          >
            <picture class="img_box more_mark">
              <img
                @load="onImgLoad(item_index)"
                v-if="item.cover == ''"
                src="/img/index.jpg"
              />
              <img @load="onImgLoad(item_index)" v-else :src="item.cover" />
            </picture>
            <div class="type_bar">
              <span>{{ item.category }}</span>
              <time>{{ item.create_time.slice(0, 10) }}</time>
            </div>
            <h4>{{ item.title }}</h4>
          </router-link>
        </template>
      </div>

      <div class="bottom_box">
        <a class="more_btn link_mark">LEARN MORE<span></span></a>
        <div class="control_box">
          <a @click="ScrollNews(-1)" class="left_arrow_btn"><i></i><i></i></a>
          <a @click="ScrollNews(1)" class="right_arrow_btn"><i></i><i></i></a>
        </div>
      </div>
    </div>
    <div class="bg_text_box">
      <span>MJP </span>
      <span>NEWS</span>
    </div>
  </section>
</template>

<script>
import home_news_gsap from "@/common/GSAP/Home/home_news.js";
export default {
  name: "HomeNews",
  mixins: [home_news_gsap],
  props: {
    page_data: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      image_loaded: 0,
    };
  },
  methods: {
    onImgLoad() {
      // console.log(`news ${index} is loaded`)
      this.image_loaded += 1;
      if (this.image_loaded == this.page_data.length) {
        this.$emit("image-loaded");
      }
    },
  },
  watch: {
    page_data() {
      this.page_data.length > 0
        ? this.SetNewsData(0, this.page_data.length)
        : "";
    },
  },
  mounted() {
    this.Init();
    this.SetNewsData(0, this.page_data.length);
  },
};
</script>