import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"

gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});

export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_customize_img_gsap: null,
            home_customize_text_gsap: null
        }
    },
    methods: {
        InitGSAP() {
            this.RegisterGsap("Home")
            let vm = this
            vm.home_customize_img_gsap = gsap.timeline({
                scrollTrigger: {
                    trigger: "#HomeCustomize",
                    // start: "top bottom", // the default values
                    // end: "bottom top",
                    scrub: true
                }
            })
            vm.home_customize_img_gsap.fromTo("#HomeCustomize .img_box", {
                yPercent: 30,
                ease: "none",
            }, {
                yPercent: -30,
                ease: "none",
            });

            let a = gsap.timeline({
                scrollTrigger: {
                    trigger: "#HomeCustomize ",
                    start: "top center", // the default values
                    end: "center center",
                    // markers: true,
                    scrub: true
                }
            })
            let images = document.querySelectorAll("#HomeCustomize .img_box img")

            a.fromTo(images[2], {
                opacity: 0
            }, {
                opacity: 1,
                ease: "none"
            }, 1)
            a.fromTo(images[1], {
                opacity: 0
            }, {
                opacity: 1,
                ease: "none"
            }, 2)
            a.fromTo(images[0], {
                opacity: 0
            }, {
                opacity: 1,
                ease: "none"
            }, 3)
            vm.home_customize_text_gsap = gsap.timeline({
                scrollTrigger: {
                    trigger: "#HomeCustomize",
                    start: "top center",
                    toggleActions: "restart play play reverse"
                }
            })
            vm.home_customize_text_gsap.fromTo("#HomeCustomize .title b", {
                y: "100%",
                ease: "none"
            }, {
                y: 0,
                direction: .4,
                ease: "none"
            }, 0)
            vm.home_customize_text_gsap.fromTo("#HomeCustomize .sub_title b", {
                y: "100%",
                ease: "none"
            }, {
                y: 0,
                direction: .4,
                delay: .4,
                ease: "none"
            }, 0)
            vm.home_customize_text_gsap.fromTo("#HomeCustomize .content", {
                opacity: 0,
                y: "20px",
                ease: "none"
            }, {
                y: 0,
                opacity: 1,
                direction: .4,
                delay: .4,
                ease: "none"
            }, 0)
            vm.home_customize_text_gsap.fromTo("#HomeCustomize .more_btn", {
                opacity: 0,
                y: "20px",
                ease: "none"
            }, {
                y: 0,
                opacity: 1,
                direction: .4,
                delay: .4,
                ease: "none"
            }, 0)
            this.InitGsapMarker()
        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.home_customize_img_gsap != null ? this.home_customize_img_gsap.kill() : ""
        this.home_customize_text_gsap != null ? this.home_customize_text_gsap.kill() : ""
    }
}