import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta';
import VueGtm from 'vue-gtm';
import gtm_methods from './common/gtm_methods';

Vue.config.productionTip = false;
Vue.mixin(gtm_methods);

Vue.use(VueAxios, axios);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
Vue.use(VueGtm, {
  id: 'GTM-MZ5DMVN',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
