<template>
  <div v-if="dialog" id="MainDialog">
    <div v-if="dialog" class="modal_box">
      <div class="modal_header">
        <a @click="Close" class="close_btn">
          <i></i>
          <i></i>
        </a>
      </div>
      <div class="modal_body">
        <p v-html="content"></p>
      </div>
      <div class="modal_footer">
        <button @click="Close" class="submit_btn link_mark">
          <div><b>關閉</b></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainDialog",
  data() {
    return {
    }
  },
  computed: {
    dialog() {
      return this.$store.state.dialog.state
    },
    content() {
      return this.$store.state.dialog.content
    }
  },
  methods: {
    Close() {
      this.$store.commit("SetDialog", { action: false, content: "" })
    }
  }
}
</script>