<template>
  <aside id="MainAside">
    <div class="container">
      <ol>
        <li v-for="item in links" :key="item.id">
          <router-link
            @click.native="$emit('toggle-menu')"
            :to="item.link"
            class="link_mark"
          >
            <p>{{ item.sub_title }}</p>
            <span>{{ item.name }}</span>
          </router-link>
        </li>
      </ol>
    </div>
  </aside>
</template>

<script>
import menu_gsap from "@/common/GSAP/main_menu.js"
export default {
  name: "MainAside",
  mixins: [menu_gsap],
  data() {
    return {

      links: [

        {
          name: "最新消息",
          sub_title: "NEWS",
          link: "/news/list/all"
        },
        {
          name: "關於我們",
          sub_title: "ABOUT",
          link: "/about"
        },
        {
          name: "產品列表",
          sub_title: "PRODUCTS",
          link: "/products/list/all"
        },
        {
          name: "客製化選色",
          sub_title: "CUSTOMIZE",
          link: "/customize_list"
        },
        {
          name: "全台經銷",
          sub_title: "DEALERS",
          link: "/dealer"
        },
        {
          name: "影音相簿",
          sub_title: "VIDEO GALLERY",
          link: "/video/list"
        },
        {
          name: "檔案下載",
          sub_title: "DOWNLOAD CENTER",
          link: "/download_center"
        },
        {
          name: "聯絡我們",
          sub_title: "CONTACT",
          link: "/contact"
        }
      ]
    }
  },
  methods: {},
  mounted() {
  }
}
</script>