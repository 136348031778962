<template>
  <div id="MainLoading" v-if="loading != 0">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 902.49 386.76">
      <g id="圖層_2" data-name="圖層 2">
        <g id="圖層_1-2" data-name="圖層 1">
          <path
            class="cls-1"
            d="M.85,0c0,.62.58.67.94.89q77,47.47,154.05,95a1.73,1.73,0,0,0,2.15,0Q235,48.38,311.92,1c.39-.24,1-.27,1-1h.85q0,62.18,0,124.36,0,82.14,0,164.29a2.71,2.71,0,0,1-1.52,2.67Q235.11,338.81,158,386.39a1.74,1.74,0,0,1-2.15,0Q78.59,338.75,1.3,291.18A2.41,2.41,0,0,1,0,288.79Q.07,145.1,0,1.42C0,.94,0,.47,0,0ZM311.14,146.85q0-70.35,0-140.72c0-.41.23-1-.15-1.21s-.76.25-1.1.45L294.17,15Q226.07,57,158,99a1.74,1.74,0,0,1-2.16,0Q80,52.17,4.05,5.44c-.37-.23-.73-.82-1.21-.5s-.13.8-.13,1.22q0,140.66,0,281.3a2.17,2.17,0,0,0,1.15,2.16q76,46.8,152,93.66a1.73,1.73,0,0,0,2.14,0q75.93-46.81,151.9-93.58a2.29,2.29,0,0,0,1.27-2.25Q311.11,217.14,311.14,146.85Z"
          />
          <path
            class="cls-1"
            d="M345.92,316.49c3-7.11,5.35-14.45,8.05-21.67,1.51-4,3-8.08,4.43-12.16.38-1.08.86-1.61,2.06-1.6q5.12.09,10.25,0c1.34,0,1.59.49,1.15,1.64-.75,2-1.47,4-2.29,6.28h14.27c.85,0,1.2-.42,1.45-1.18.83-2.44,1.63-4.89,2.63-7.26.63-1.5.23-1.73-1.22-1.72-8.16.05-16.32,0-24.48,0-2.38,0-2.43-.08-1.61-2.3,1.18-3.19,2.41-6.38,3.5-9.61a1.89,1.89,0,0,1,2.12-1.49c11.09,0,22.18,0,33.26,0a5.21,5.21,0,0,1,4.79,6.76c-2.8,7.87-5.56,15.76-8.64,23.52a10,10,0,0,1-9.6,6.55c-6.48.11-13,.07-19.45,0a2.22,2.22,0,0,0-2.55,1.77c-1.33,4-2.9,7.83-4.22,11.79a2.38,2.38,0,0,1-2.81,1.93c-3.69-.13-7.39,0-11.09,0Z"
          />
          <path
            class="cls-1"
            d="M531.42,148.06c-2.51,4.73-5,9.46-7.54,14.18-5.14,9.54-10.75,18.76-18.05,26.84a49.91,49.91,0,0,1-10.94,9.35c-9.6,5.86-19.24,5.92-28.8,0-7.31-4.55-12.75-11-17.72-17.87-6.91-9.59-12.22-20.13-17.82-30.49-.36-.66-.75-1.31-1.14-2-.77.43-.41,1-.41,1.5q0,42.93,0,85.86c0,2-.54,2.56-2.57,2.55q-20.19-.14-40.4,0c-1.85,0-2.37-.5-2.37-2.35q.07-65.85.05-131.72c0-15.31,9.21-28.63,23.05-33.37,11.21-3.84,21.26-1.23,30.42,5.86,8,6.19,14.09,14,19.73,22.33,8.46,12.43,14.79,26.06,22.12,39.12a1.11,1.11,0,0,0,.09.19c.46.58.48,1.73,1.25,1.77s.92-1.08,1.27-1.71c7.9-14.42,15.23-29.17,24.64-42.73,5-7.19,10.56-13.85,17.55-19.25,19-14.64,41.73-5.79,50.39,13.18a34.5,34.5,0,0,1,3,14.44q0,66,0,131.93c0,1.9-.63,2.32-2.41,2.31q-20.31-.1-40.61,0c-1.81,0-2.39-.41-2.38-2.3q.09-42.83,0-85.65v-1.83Z"
          />
          <path
            class="cls-1"
            d="M725.36,177.51c0-19.26,0-38.53-.05-57.8,0-1.83.44-2.34,2.31-2.33q20.42.14,40.83,0c2.08,0,2.52.63,2.48,2.59-.12,6.7,0,13.4-.08,20.1,0,1.48.41,1.86,1.88,1.85q18.21-.09,36.43,0c1.62,0,2-.51,2-2-.07-7.33-.1-14.66,0-22,0-1.82-.59-2.15-2.26-2.15-27,0-54,0-81,.08-2,0-2.54-.52-2.53-2.53q.13-21.15,0-42.3c0-1.74.44-2.21,2.19-2.2q53.07.08,106.15,0A16.92,16.92,0,0,1,844.54,73c5.1,4.14,9.47,8.86,11.4,15.39a12.66,12.66,0,0,1,.46,3.72c0,24.5-.16,49,.08,73.5.1,10.59-10.68,23.66-23.53,23.46-20-.3-39.92,0-59.88-.13-1.89,0-2.24.52-2.23,2.3.08,14.8,0,29.6.09,44.4,0,1.87-.52,2.33-2.35,2.32q-20.52-.12-41,0c-1.75,0-2.23-.48-2.22-2.22C725.38,216.33,725.36,196.92,725.36,177.51Z"
          />
          <path
            class="cls-1"
            d="M717.36,143.66q0,35,0,69.94c0,10.59-5.64,19.14-14.9,22.86a22,22,0,0,1-8.19,1.45q-42.5,0-85,0a23.22,23.22,0,0,1-22.92-22.77c-.12-12.92,0-25.83-.08-38.74,0-1.62.52-2,2-2q20.63.08,41.25,0c1.67,0,2.17.49,2.13,2.14-.1,4.19,0,8.38-.07,12.56,0,1.43.44,1.75,1.79,1.75,12.28-.05,24.57-.07,36.85,0,1.79,0,1.81-.78,1.81-2.14Q672,130,672,71.26c0-1.88.39-2.49,2.38-2.47q20.42.13,40.83,0c1.74,0,2.2.44,2.19,2.19Q717.31,107.32,717.36,143.66Z"
          />
          <path
            class="cls-1"
            d="M668.75,289.38c1.91-2.05,3.53-3.77,5.12-5.5a125.77,125.77,0,0,1,13.44-13.18,23.84,23.84,0,0,1,9.31-5c6-1.45,10.81,3.46,8.77,9.32-4.31,12.36-8.88,24.63-13.35,36.93-.69,1.89-.95,4.39-2.31,5.46s-4,.28-6.11.35c-1.81.05-3.63-.05-5.44,0-1.24.05-1.6-.21-1.12-1.51q4.29-11.55,8.47-23.15c.64-1.76,1.16-3.56,1.73-5.3-.68-.42-.93,0-1.17.33A102.16,102.16,0,0,1,674,300.82a26.22,26.22,0,0,1-8,5.22c-4.21,1.61-7.45.47-9.76-3.47-2.09-3.57-2.69-7.59-3.52-11.54a21.85,21.85,0,0,0-1.11-4.14q-1.62,4.44-3.26,8.88-3.76,10.31-7.5,20.62c-.28.77-.44,1.39-1.48,1.37-4,0-8.08,0-12.37,0,2-5.49,3.93-10.77,5.86-16.05,3.18-8.7,6.26-17.43,9.56-26.08,2.1-5.51,6.37-8.71,12-10,3.32-.75,6,.54,8.05,3.23,2.46,3.25,3.54,7.07,4.36,11C667.46,282.88,668.05,285.93,668.75,289.38Z"
          />
          <path
            class="cls-1"
            d="M747,317.75c4-11.15,8-21.94,11.89-32.73,1.16-3.2,2.23-6.45,3.56-9.59a16.16,16.16,0,0,1,11.76-9.76c4.74-1.13,8,1,9.35,5.66,2.37,8.45,4.77,16.89,7.18,25.42.75-.27.76-1,.95-1.47,3.42-9.35,6.85-18.7,10.19-28.08.45-1.26,1-1.79,2.35-1.76,3.35.09,6.7.07,10,0,1.32,0,1.58.43,1.14,1.62-5,13.66-9.93,27.36-15,41a15,15,0,0,1-15.72,9.66,5.54,5.54,0,0,1-5-4.37c-2.36-8.09-4.65-16.2-7-24.31-.21-.72-.42-1.45-.81-2.82-1.12,3-2,5.51-2.93,8-2.67,7.32-5.37,14.64-8,22a2,2,0,0,1-2.19,1.59C754.91,317.7,751.08,317.75,747,317.75Z"
          />
          <path
            class="cls-1"
            d="M624.54,317.75c-5.09,0-10,0-14.84,0-1,0-1.08-.57-1.28-1.27-1.18-4.16-2.47-8.28-3.54-12.46-.36-1.4-1-1.73-2.32-1.73-4.6,0-4.62-.06-6.23,4.25-1.21,3.26-2.4,6.53-3.54,9.82a1.73,1.73,0,0,1-1.95,1.39c-3.49-.07-7-.06-10.46,0-1.23,0-1.42-.36-1-1.44q6.17-16.86,12.3-33.74a1.91,1.91,0,0,1,2.11-1.53c3.35.08,6.7.07,10.05,0,1.42,0,1.72.44,1.18,1.73-.65,1.54-1.15,3.13-1.77,4.69-.4,1-.39,1.54.94,1.52,4.26-.05,8.51,0,12.77,0a1.37,1.37,0,0,0,1.52-1.05c.94-2.62,1.95-5.22,2.87-7.84.61-1.72-.79-1.23-1.53-1.24-8,0-15.91,0-23.86,0-2.92,0-2.92,0-1.9-2.83s2.19-5.87,3.19-8.83a2.25,2.25,0,0,1,2.58-1.76c10.74.06,21.48,0,32.22,0,4.26,0,6.72,3.48,5.26,7.51-2.62,7.26-5.35,14.49-7.92,21.78a11.54,11.54,0,0,1-6.38,6.8c-2.67,1.25-2.67,1.22-1.83,4.16C622.28,309.66,623.38,313.6,624.54,317.75Z"
          />
          <path
            class="cls-1"
            d="M439.54,317.75c4.35-12,8.58-23.62,12.78-35.26a1.83,1.83,0,0,1,2-1.43c3.35.06,6.7.08,10,0,1.64,0,1.75.6,1.22,1.89-.62,1.55-1.09,3.16-1.73,4.7-.4,1-.3,1.36.86,1.35,4.26,0,8.51,0,12.77,0a1.56,1.56,0,0,0,1.74-1.35c.74-2.32,1.55-4.62,2.51-6.86.67-1.58.42-2-1.34-2-8.09.08-16.18,0-24.27,0-2.41,0-2.43-.05-1.6-2.29,1.18-3.2,2.39-6.39,3.48-9.62a1.91,1.91,0,0,1,2.13-1.49c11.09,0,22.18,0,33.27,0a5.22,5.22,0,0,1,4.77,6.79q-4.21,11.89-8.69,23.72a10,10,0,0,1-6.9,5.93c-1.55.47-1.58,1.08-1.19,2.37,1.19,3.93,2.23,7.9,3.42,11.84.39,1.32.11,1.68-1.28,1.65-4.18-.08-8.37-.07-12.55,0a1.72,1.72,0,0,1-2-1.5c-1-3.9-2.23-7.76-3.23-11.67-.43-1.65-1.05-2.4-2.95-2.32-4.3.18-4.3.06-5.76,4.08-1.23,3.4-2.48,6.8-3.69,10.21-.25.7-.45,1.21-1.38,1.2C447.88,317.72,443.84,317.75,439.54,317.75Z"
          />
          <path
            class="cls-1"
            d="M739.41,287.15l-8.9,9.79c-.25.17.4-.16.88-.16,5.37,0,10.75.06,16.12,0,1.65,0,2.27.24,1.58,2.05-2.19,5.79-4.26,11.63-6.39,17.45-.28.77-.47,1.53-1.65,1.51-4-.07-8.08,0-12.34,0,.77-2.13,1.43-4.1,2.2-6,.4-1,.49-1.56-.88-1.55-4.39,0-8.79,0-13.19,0a2,2,0,0,0-1.65.71,12.68,12.68,0,0,0-1.05,1c-3.5,5-8.23,6.75-14.21,5.87a51.92,51.92,0,0,0-7.18-.05c.1-.35.09-.57.2-.68q23.52-23.26,47.07-46.49c3.07-3,6.56-5.2,11.07-5.1,4.69.11,8.09,4.29,6.69,8.74-2,6.51-4.53,12.87-6.81,19.3-.32.93-1,1-1.84,1-3.42,0-6.84,0-10.25,0-1.52,0-1.61-.59-1.13-1.79.71-1.79,1.33-3.61,2-5.42Z"
          />
          <path
            class="cls-1"
            d="M409.5,317.75c-5,0-10.05,0-15.07,0-1.35,0-1.87-.12-1.29-1.69q9-24.4,17.78-48.87a2.28,2.28,0,0,1,2.56-1.78q14.76.12,29.51,0a2.15,2.15,0,0,1,2.46,1.88c.87,3.15,1.83,6.29,2.88,9.38.55,1.63.31,2.21-1.57,2.19-8.24-.08-16.47,0-24.7-.07a2.23,2.23,0,0,0-2.54,1.77c-2.64,7.48-5.33,15-8.13,22.37-.6,1.6-.11,1.73,1.27,1.73q12.24-.06,24.49,0c.52,0,1.25-.32,1.54.25.39.76-.47,1.05-.88,1.45-3.55,3.46-7.13,6.87-10.66,10.35a3.34,3.34,0,0,1-2.58,1C419.55,317.72,414.52,317.75,409.5,317.75Z"
          />
          <path
            class="cls-1"
            d="M863,317.75c-5,0-10,0-15.06,0-1.47,0-1.74-.26-1.21-1.71q9-24.49,17.84-49.05a2.07,2.07,0,0,1,2.3-1.57c10,.06,19.95,0,29.93,0,1,0,1.65.15,2,1.27,1.21,3.61,2,7.35,3.54,10.87.63,1.44-.74,1.25-1.6,1.25H877.29c-3.59,0-3.61,0-4.8,3.28q-3.66,10.11-7.31,20.23c-.82,2.29-.82,2.3,1.71,2.3,8,0,15.91,0,23.86,0,.51,0,1.22-.34,1.51.3s-.44.94-.81,1.3c-3.55,3.46-7.14,6.88-10.65,10.36a3.52,3.52,0,0,1-2.76,1.13C873,317.72,868,317.75,863,317.75Z"
          />
          <path
            class="cls-1"
            d="M553.1,317.75c-4.81,0-9.63-.19-14.43.06-3.54.19-5.67-2.71-4.47-6.15,3.46-9.85,7.1-19.62,10.65-29.44a1.49,1.49,0,0,1,1.53-1.14h10.88c1.65,0,1.12,1,.81,1.84q-3.54,9.7-7.08,19.41c-.86,2.34-.88,2.34,1.64,2.34,3.63,0,7.25-.06,10.88,0a2.22,2.22,0,0,0,2.57-1.75c2.33-6.7,4.88-13.33,7.22-20a2.37,2.37,0,0,1,2.73-1.87c3.14.09,6.28.06,9.42,0,1.38,0,1.9.21,1.32,1.78-3.41,9.2-6.7,18.44-10.08,27.65-1.88,5.13-5,7.25-10.41,7.25Z"
          />
          <path
            class="cls-1"
            d="M529.29,278.86c-4,0-8,.11-11.93-.06-1.73-.07-2.43.56-3,2.09-4.2,11.73-8.5,23.43-12.69,35.16-.46,1.27-1,1.77-2.34,1.73-3.35-.1-6.7-.09-10.05,0-1.43,0-1.64-.34-1.17-1.65q7.5-20.38,14.9-40.81c1-2.74,2-5.49,3-8.25a2,2,0,0,1,2.24-1.64q15,.12,29.94,0a1.94,1.94,0,0,1,2.21,1.63c1,3.34,2,6.67,3.06,10,.46,1.42.09,1.86-1.39,1.83C537.8,278.81,533.54,278.86,529.29,278.86Z"
          />
          <path
            class="cls-1"
            d="M820.77,317.75c-4.11,0-8.24-.2-12.34.06-3.48.21-5.47-2.76-4.43-6,.94-3,2.08-5.91,3.15-8.85,2.4-6.61,4.86-13.2,7.18-19.84a2.61,2.61,0,0,1,3-2c3.13.13,6.27.06,9.41,0,1.36,0,1.71.29,1.18,1.71-2.51,6.71-4.87,13.49-7.39,20.2-.59,1.55-.17,1.72,1.26,1.71,8.16-.05,16.33,0,24.49,0,.53,0,1.25-.29,1.55.26.4.77-.46,1.06-.86,1.46-3.55,3.45-7.15,6.87-10.68,10.34a3.3,3.3,0,0,1-2.58,1C829.43,317.72,825.1,317.75,820.77,317.75Z"
          />
          <path
            class="cls-1"
            d="M567.17,278.86c-6.35,0-12.7,0-19,0-1.58,0-2.07-.42-1.36-1.89a2.58,2.58,0,0,0,.14-.39c2.78-8.7,5.41-11.62,16-11.2,7.6.31,15.21,0,22.82.07,4.28,0,6.28,2.77,4.83,6.78-.78,2.15-.92,5-2.64,6.23s-4.32.32-6.53.35C576.66,278.89,571.92,278.86,567.17,278.86Z"
          />
          <path
            class="cls-1"
            d="M836.91,278.86H818.29c-2.35,0-2.31,0-1.56-2.3,2.66-8.16,5.14-11.62,15.64-11.13,6.61.3,13.25.08,19.87,0a2,2,0,0,1,2.34,1.72c.9,3.28,2,6.52,3,9.77.45,1.38.22,2-1.45,1.95C849.73,278.81,843.32,278.86,836.91,278.86Z"
          />
          <path
            class="cls-1"
            d="M429.28,285.12c2.51,0,5,0,7.53,0,.46,0,1.07-.25,1.34.27s-.37.8-.68,1.11c-3.75,3.64-7.52,7.25-11.27,10.89a2.88,2.88,0,0,1-2.18,1c-2.37,0-4.74,0-7.11,0-1.14,0-1.5-.33-1.06-1.49q2-5.37,3.89-10.79c.33-.94,1-1,1.81-1Z"
          />
          <path
            class="cls-1"
            d="M515.8,298.41c-5.42,0-5.42,0-3.7-4.78.86-2.35,1.78-4.69,2.54-7.07a1.71,1.71,0,0,1,1.9-1.46c5.16,0,10.32,0,15.48,0,.4,0,.92-.17,1.15.26.32.63-.35.82-.67,1.12-3.75,3.64-7.57,7.22-11.26,10.91C519.48,299.18,517.38,298,515.8,298.41Z"
          />
          <path
            class="cls-1"
            d="M890.89,285.13c-3.7,4.06-7.63,8.39-11.58,12.71a2.38,2.38,0,0,1-1.94.57c-2.24,0-4.47,0-6.7,0-1.23,0-1.86-.1-1.24-1.63,1.34-3.36,2.49-6.8,3.75-10.2.22-.6.25-1.46,1.23-1.46Z"
          />
          <path
            class="cls-1"
            d="M302.88,152.05q0,65.25,0,130.49a2.52,2.52,0,0,1-1.37,2.48Q229.8,329.18,158.1,373.4a2,2,0,0,1-2.41,0Q93.59,335,31.47,296.8c-1.48-.92-1.48-.91,0-1.84q33.84-21.27,67.66-42.55a2.06,2.06,0,0,1,2.51,0q26.85,15.22,53.72,30.39a2.58,2.58,0,0,0,3,.11q36.72-20.82,73.5-41.53a2.14,2.14,0,0,0,1.31-2.17q-.06-43.14,0-86.28c0-1.1-.06-1.44-1.23-.78Q195,173,158.15,193.79a2,2,0,0,1-2.32.1Q119,173,82.09,152.21c-1.36-.77-1.35-.77-1.35.8q0,43,0,86c0,.9-.1,1.71,1,2.22,1,.45,0,.79-.34,1-2.46,1.59-5,3.15-7.43,4.71L14.24,284.46c-.64.4-1.18,1-2.16.44a2.22,2.22,0,0,1-1.14-2.19Q11,152,11,21.31c0-.46-.22-1.07.18-1.34s.95.23,1.37.49q71.55,44.07,143.07,88.17a2.18,2.18,0,0,0,2.67,0q68.25-42.1,136.54-84.15c2.3-1.42,4.61-2.81,6.88-4.26.86-.54,1.3-.53,1.22.64,0,.51,0,1,0,1.56Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>


<script>
import GSAP from "@/common/SmoothScrollResister.js"
export default {
  name: "MainLoading",
  mixins: [GSAP],
  computed: {
    loading() {
      return this.$store.state.loading
    }
  },
  mounted() {
    this.ModalToggle(true)
  },
  watch: {
    loading() {
      // this.loading ? this.ModalToggle(true) : this.ModalToggle(false)
    }
  }
}
</script>