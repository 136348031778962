import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});

export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_product_feature_gsap: null
        }
    },
    methods: {
        InitGSAP() {
            this.RegisterGsap("Home")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:769px)"() {
                    vm.home_product_feature_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#ProductFeature .content_box",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse"
                            // markers: true,
                        }
                    })
                    vm.home_product_feature_gsap.fromTo("#ProductFeature .img_box ", {
                        webkitFilter: "blur(40px)",
                        y: "100px",
                        opacity: 0,
                    }, {
                        webkitFilter: "blur(0px)",
                        y: 0,
                        opacity: 1,
                        duration: 1,
                        ease: "power2.out",
                    }, 0)
                    vm.home_product_feature_gsap.to("#ProductFeature .sub_title b", {
                        y: 0,
                        duration: .4
                    }, 0)
                    vm.home_product_feature_gsap.to("#ProductFeature .title b", {
                        y: 0,
                        delay: .4,
                        duration: .3
                    }, 0)
                    vm.home_product_feature_gsap.to("#ProductFeature .content", {
                        y: 0,
                        opacity: 1,
                        delay: 1,
                        duration: .6
                    }, 0)
                    vm.home_product_feature_gsap.from("#ProductFeature .bg_text_box", {
                        y: "50px",
                        opacity: 0,
                        duration: 1
                    }, 0)
                },
                "(max-width:768px)"() {
                    vm.home_product_feature_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#ProductFeature .img_box",
                            start: "top center", // the default values
                            // markers: true,
                            toggleActions: "restart play play reverse"
                        }
                    })
                    vm.home_product_feature_gsap.fromTo("#ProductFeature .img_box", {
                        webkitFilter: "blur(40px)",
                        y: "100px",
                        opacity: 0,
                    }, {
                        webkitFilter: "blur(0px)",
                        y: 0,
                        opacity: 1,
                        duration: .6,
                        ease: "power2.out",
                    }, 0)
                    vm.home_product_feature_gsap.to("#ProductFeature .sub_title b", {
                        y: 0,
                        duration: .6
                    }, 0)
                    vm.home_product_feature_gsap.to("#ProductFeature .title b", {
                        y: 0,
                        delay: .3,
                        duration: .6
                    }, 0)
                    vm.home_product_feature_gsap.to("#ProductFeature .content", {
                        y: 0,
                        opacity: 1,
                        delay: 1,
                        duration: .6
                    }, 0)
                    vm.home_product_feature_gsap.from("#ProductFeature .bg_text_box", {
                        y: "50px",
                        opacity: 0,
                        duration: 1
                    }, 0)
                }
            })
            this.InitGsapMarker()
        }
    },
    beforeDestroy() {
        this.home_product_feature_gsap != null ? this.home_product_feature_gsap.kill() : ""
    }
}