import axios from "axios";
import qs from "qs";
// import store from "@/store/index.js"

let baseURL = process.env.VUE_APP_BASE_API;
// 建立axios例項
const service = axios.create({
    baseURL: baseURL,
    timeout: 30000, // 請求超時時間
    // headers: {
    //     "Content-Type": "application/json;charset=UTF-8"
    // },
});
// axios.defaults.withCredentials = true; // 若跨域請求需要帶 cookie 身份識別
// axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*"; // 允許跨域
const err = (error) => {
    if (error.response) {
        let data = error.response.data;
        console.log(`message: ${data.msg}`)
    }
    return Promise.reject(error);
};
// request攔截器
service.interceptors.request.use(
    (config) => {
        // const token = this.$store.state.token;
        // if (this.$store.state.token) {
        //     config.headers["X-Token"] = token;
        // }
        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);
// respone攔截器
service.interceptors.response.use((response) => {
    // console.log("response.data", response.data);
    return response.data;
}, err);

/*
 *  get請求
 *  url:請求地址
 *  params:引數
 * */
export function get(url, params = {}) {
    // store.commit("SetPageLoading", 1)
    return new Promise((resolve, reject) => {
        // console.log("process.env.VUE_APP_BASE_API", process.env.VUE_APP_BASE_API);
        service({
                url: url,
                method: "get",
                params: params,
            })
            .then((response) => {
                resolve(response);
                // store.commit("SetPageLoading", -1)
            })
            .catch((error) => {
                reject(error);
                // store.commit("SetPageLoading", -1)
            });
    });
}

/*
 *  post請求
 *  url:請求地址
 *  params:引數
 * */
export function post(url, params = {}) {
    // store.commit("SetPageLoading", 1)
    return new Promise((resolve, reject) => {
        service({
                url: url,
                method: "post",
                data: qs.stringify(params),
            })
            .then((response) => {
                resolve(response);
                // store.commit("SetPageLoading", -1)
            })
            .catch((error) => {
                reject(error);
                // store.commit("SetPageLoading", -1)
            });
    });
}



export default {
    get,
    post
};