import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});
export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            active_product: 0,
            total_product: 6,
            home_hot_product_gsap: null
        }
    },
    methods: {
        SetProductData(active_product, total_product) {
            this.total_product = total_product
            this.active_product = active_product
        },
        Reset() {
            this.active_product = 0
        },
        GetCardWidth() {
            return 330
        },
        ScrollProduct(val) {
            if (val == 1) {
                //next
                this.active_product == this.total_product - 1 ? this.active_product = 0 : this.active_product += 1
            } else {
                //prev
                this.active_product == 0 ? "" : this.active_product -= 1
            }

            gsap.to("#HomeHotProduct .list", {
                x: this.active_product * this.GetCardWidth() * -1 + "px",
                ease: "power2.out"
            })
        },
        InitGSAP() {
            this.RegisterGsap("Home")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:600px)"() {
                    vm.home_hot_product_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#HomeHotProduct",
                            start: "top center",
                            end: "center center",
                            // markers: true,
                            toggleActions: "restart play complete reverse",
                        }
                    })
                    vm.home_hot_product_gsap.add(function () {
                        vm.Reset()
                    })
                    vm.home_hot_product_gsap.fromTo("#HomeHotProduct .list", {
                        opacity: 0,
                        x: "300",
                    }, {
                        ease: "power2.out",
                        opacity: 1,
                        duration: 1,
                        x: "0"
                    }, 1)
                    vm.home_hot_product_gsap.to("#HomeHotProduct .sub_title b", {
                        y: 0,
                        duration: .4
                    }, 0)
                    vm.home_hot_product_gsap.to("#HomeHotProduct .title b", {
                        y: 0,
                        delay: .4,
                        duration: .3
                    }, 0)
                    vm.home_hot_product_gsap.fromTo("#HomeHotProduct .more_btn", {
                        x: "-30px",
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        delay: .4,
                        duration: .3
                    }, 0)
                    vm.home_hot_product_gsap.from("#HomeHotProduct .control_box", {
                        y: "50px",
                        opacity: 0,
                        delay: .4,
                        duration: 1
                    }, 1)
                },
                "(max-width:600px)"() {
                    vm.home_hot_product_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#HomeHotProduct",
                            start: "top center",
                            end: "center center",
                            // markers: true,
                            toggleActions: "restart play complete reverse",
                        }
                    })
                    vm.home_hot_product_gsap.add(function () {
                        vm.Reset()
                    })
                    vm.home_hot_product_gsap.fromTo("#HomeHotProduct .list", {
                        opacity: 0,
                        x: "300",
                    }, {
                        ease: "power2.out",
                        opacity: 1,
                        duration: 1,
                        x: "0"
                    }, 1)
                    vm.home_hot_product_gsap.to("#HomeHotProduct .sub_title b", {
                        y: 0,
                        duration: .4
                    }, 0)
                    vm.home_hot_product_gsap.to("#HomeHotProduct .title b", {
                        y: 0,
                        delay: .4,
                        duration: .3
                    }, 0)
                    vm.home_hot_product_gsap.fromTo("#HomeHotProduct .more_btn", {
                        x: "-30px",
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        delay: .4,
                        duration: .3
                    }, 0)
                    vm.home_hot_product_gsap.from("#HomeHotProduct .control_box", {
                        y: "50px",
                        opacity: 0,
                        delay: .4,
                        duration: 1
                    }, 1)
                },
            })
            this.InitGsapMarker()
        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.home_hot_product_gsap != null ? this.home_hot_product_gsap.kill() : ""
    }
}