import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);
// gsap.config({
//     autoSleep: 60,
//     force3D: true,
//     nullTargetWarn: false,
//     trialWarn: false,
//     units: {
//         left: "%",
//         top: "%",
//         rotation: "rad"
//     }
// });

export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_video_mask_gsap: null,
            home_video_text_gsap: null
        }
    },
    methods: {
        InitGSAP() {
            ScrollTrigger.refresh();
            this.RegisterGsap("Home")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:768px)"() {
                    vm.home_video_mask_gsap = gsap.timeline({
                            scrollTrigger: {
                                trigger: "#HomeVideo .container",
                                triggerHook: 0,
                                start: "top top",
                                end: "bottom center",
                                scrub: true,
                                pin: true,
                                pushFollowers: true,
                                anticipatePin: 1,
                                pinSpacing: false,
                                // markers: true
                            }
                        })
                        .to("#HomeVideo .container", {
                            maskSize: "1600%",
                            ease: "none",
                        }, 0)
                        .to("#HomeVideo .red_cover", {
                            opacity: 0.2,
                            backgroundColor: "#000",
                            ease: "none",
                        }, 0)

                    vm.home_video_text_gsap = gsap.timeline({
                            scrollTrigger: {
                                trigger: "#HomeVideo .container",
                                triggerHook: 0,
                                start: "top top",
                                end: "30% center",
                                toggleActions: "restart play complete reverse",
                                pin: true,
                                // markers: true,
                                anticipatePin: 1,
                            }
                        })
                        .fromTo("#HomeVideo .sub_title b", {
                            y: 100,
                            opacity: 0,
                        }, {
                            y: 0,
                            opacity: 1,
                            duration: .4
                        }, 1)
                        .fromTo("#HomeVideo .title b", {
                            y: 100,
                            opacity: 0
                        }, {
                            y: 0,
                            opacity: 1,
                            delay: .4,
                            duration: .3
                        }, 1)
                        .fromTo("#HomeVideo .more_btn", {
                            y: 100,
                            opacity: 0
                        }, {
                            y: 0,
                            opacity: 1,
                            delay: .4,
                            duration: .3
                        }, 1)
                },
                "(max-width:768px)"() {
                    vm.home_video_mask_gsap = gsap.timeline({
                            scrollTrigger: {
                                trigger: "#HomeVideo .container",
                                // triggerHook: 0,
                                start: "top top",
                                end: 'bottom center',
                                scrub: true,
                                pin: true,
                                // pushFollowers: true,
                                // anticipatePin: 1,
                                // markers: true
                            }
                        })
                        .to("#HomeVideo .container", {
                            maskSize: "2500%",
                            ease: "none",
                        }, 0)
                        .to("#HomeVideo .red_cover", {
                            opacity: 0.2,
                            backgroundColor: "#000",
                            ease: "none",
                        }, 0)

                    // vm.home_video_text_gsap = gsap.timeline({
                    //         scrollTrigger: {
                    //             trigger: "#HomeVideo",
                    //             triggerHook: 0,
                    //             start: "top top",
                    //             end: window.innerHeight * 5,
                    //             toggleActions: "restart play complete reverse",
                    //             pin: true,
                    //             // markers: true,
                    //             anticipatePin: 1,
                    //         }
                    //     })
                    //     .fromTo("#HomeVideo .sub_title b", {
                    //         y: 100,
                    //         opacity: 0,
                    //     }, {
                    //         y: 0,
                    //         opacity: 1,
                    //         duration: .4
                    //     }, 1)
                    //     .fromTo("#HomeVideo .title b", {
                    //         y: 100,
                    //         opacity: 0
                    //     }, {
                    //         y: 0,
                    //         opacity: 1,
                    //         delay: .4,
                    //         duration: .3
                    //     }, 1)
                    //     .fromTo("#HomeVideo .more_btn", {
                    //         y: 100,
                    //         opacity: 0
                    //     }, {
                    //         y: 0,
                    //         opacity: 1,
                    //         delay: .4,
                    //         duration: .3
                    //     }, 1)
                },
                // "(max-width:600px)"() {
                //     vm.home_video_mask_gsap = gsap.timeline({
                //             scrollTrigger: {
                //                 trigger: "#HomeVideo",
                //                 triggerHook: 0,
                //                 start: "top top",
                //                 end: window.innerHeight * 5,
                //                 scrub: true,
                //                 pin: true,
                //                 // markers: true,
                //                 anticipatePin: 1,
                //             }
                //         })
                //         .to("#HomeVideo", {
                //             maskSize: "5500%",
                //             ease: "none",
                //         }, 0)
                //         .to("#HomeVideo .red_cover", {
                //             opacity: 0.2,
                //             backgroundColor: "#000",
                //             ease: "none",
                //         }, 0)

                //     vm.home_video_text_gsap = gsap.timeline({
                //             scrollTrigger: {
                //                 trigger: "#HomeVideo",
                //                 triggerHook: 0,
                //                 start: "top top",
                //                 end: window.innerHeight * 5,
                //                 toggleActions: "restart play complete reverse",
                //                 pin: true,
                //                 // markers: true,
                //                 anticipatePin: 1,
                //             }
                //         })
                //         .fromTo("#HomeVideo .sub_title b", {
                //             y: 100,
                //             opacity: 0,
                //         }, {
                //             y: 0,
                //             opacity: 1,
                //             duration: .4
                //         }, 1)
                //         .fromTo("#HomeVideo .title b", {
                //             y: 100,
                //             opacity: 0
                //         }, {
                //             y: 0,
                //             opacity: 1,
                //             delay: .4,
                //             duration: .3
                //         }, 1)
                //         .fromTo("#HomeVideo .more_btn", {
                //             y: 100,
                //             opacity: 0
                //         }, {
                //             y: 0,
                //             opacity: 1,
                //             delay: .4,
                //             duration: .3
                //         }, 1)
                // },
            })
            this.InitGsapMarker()
        },
        Refresh() {
            ScrollTrigger.refresh()
            // alert("Refresh")
        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.home_video_mask_gsap != null ? this.home_video_mask_gsap.kill() : ""
        this.home_video_text_gsap != null ? this.home_video_text_gsap.kill() : ""
    }
}